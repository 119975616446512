<template>
  <section>
    <div class="content" v-if="can">
      <div class="box box-solid">
        <router-view
          v-if="contract"
          class="inner-panel tab-content"
          :connector_id="connector_id"
          :device_id="device_id"
          :data_id="data_id"
          :alarm_id="alarm_id"
          v-on:rule="setRule"
          @loading="loading"
        />
        <Spin v-if="busy" />
      </div>
    </div>
    <InfoBox v-else preset="unauthorized" />
  </section>
</template>

<script>
import InfoBox from "@/components/info-box.vue";
import Spin from "@/components/spin.vue";
export default {
  name: "DashboardEdit",
  components: {
    InfoBox,
    Spin
  },
  props: {
    connector_id: {
      type: Number,
      default: 0,
      required: false
    },
    device_id: {
      type: Number,
      default: 0,
      required: false
    },
    data_id: {
      type: Number,
      default: 0,
      required: false
    },
    alarm_id: {
      type: Number,
      default: 0,
      required: false
    }
  },
  data() {
    return {
      busy: false,
      rule: ""
    };
  },
  computed: {
    can() {
      return !this.rule || this.$can("manage", this.rule);
    },
    contract() {
      return this.$store.getters["user/contract"] || null;
    }
  },
  methods: {
    setRule(rule) {
      this.rule = rule;
    },
    fetchEquipmentList: function() {
      this.busy = true;
      this.$store.dispatch("fetchEquipmentList").then(() => {
        this.busy = false;
      });
    },
    loading(state) {
      this.busy = state;
    }
  },
  created: function() {
    this.$store.dispatch("processArea/fetchProcessAreas");
    if (
      !this.$store.getters.equipmentList ||
      !this.$store.getters.equipmentList.length
    ) {
      this.fetchEquipmentList();
    }
  },
  mounted: function() {
    this.$root.$emit("controlSidebar:setContent", null);
    this.$emit("equipmentChanged");
  }
};
</script>

<style scoped>
.content {
  padding: 0;
}
.inner-panel {
  /* min-height: 400px; */
  min-height: calc(100dvh - 104px);
}
</style>
